<template>
  <div class="pagess">
    <img
      src="https://cdn.bubbleplan.cn/static/home/back_cc.png"
      alt=""
      class="pagess-bach"
      @click.stop="back()"
    />
    <div class="userAgreement">
      <div class="userAgreement-title">泡泡计划隐私政策</div>
      <div class="userAgreement-text">
        泡泡计划隐私政策本版本发布日期:2024年07月20日生效日期:2024年07月20日
      </div>
      <div class="userAgreement-text">概述</div>
      <div class="userAgreement-text">本政策将帮助您了解以下内容:</div>
      <div class="userAgreement-text">我们如何收集和使用您的个人信息</div>
      <div class="userAgreement-text">我们如何使用Cookie等技术</div>
      <div class="userAgreement-text">我们如何存储您的个人信息</div>
      <div class="userAgreement-text">我们如何保护您的个人信息</div>
      <div class="userAgreement-text">我们如何对外提供个人信息</div>
      <div class="userAgreement-text">您如何查看您的个人信息权利</div>
      <div class="userAgreement-text">对第三方责任的声明</div>
      <!-- <div class="userAgreement-bole">二、账户注册、管理和注销</div> -->
      <div class="userAgreement-text">我们如何保护未成年人的信息</div>
      <div class="userAgreement-text">
        本政策的适用和更新本政策中关键词说明《泡泡计划》(以下简称"我们")通过下述途径向用户(以下简称"您")提供的各项产品/服务:包括但不限于客户端以及开放平台账号或小程序等我们深知个人信息对用户("您")的重要性。我们非常注重保护您的个人信息及隐私,我们希望通过本隐私政策(以下简称:"本政策")向您介绍在使用我们的服务时,我们将如何处理您的个人信息,以及我们为您提供的访问、更正、删除和保护这些信息的方式。本政策与您使用我们的产品/服务关系紧密,我们建议您仔细阅读并理解本政策全部内容,做出您认为适当的选择。对本政策中与您的权益存在重大关系的条款和个人信息,我们将在正文中以粗体字和/或下划线进行标注以提示您注意。其中要点如下:
      </div>
      <div class="userAgreement-text">
        1.为了便于您了解在使用我们的服务时,我们需要收集的信息类型与用途,我们将结合具体服务向您逐一说明。
      </div>
      <div class="userAgreement-text">
        2.为了向您提供服务所需,我们会按照合法、正当、必要、诚信的原则收集您的信息。
      </div>
      <div class="userAgreement-text">
        3.如果为了向您提供服务而需要将您的信息共享至第三方,我们将评估该第三方收集信息的合法性、正当性、必要性。我们将要求第三方对您的信息采取保护措施并且严格遵守相关法律法规与监管要求。另外,我们会按照法律法规及国家标准的要求以确认协议、具体场景下的文案确认、弹窗提示等形式征得您的同意或确认第三方已经征得您的同意。
      </div>
      <div class="userAgreement-text">
        4.如果为了向您提供服务而需要从第三方获取您的信息，我们将要求第三方说明信息来源，并要求第三方保障其提供信息的合法性，如果我们开展业务需进行的个人信息处理活动超出您原本向第三方提供个人信息时的授权范围，我们将征得您的明确同意。
      </div>
      <div class="userAgreement-text">
        5.您可以通过本政策介绍的方式访问和管理您的信息、设置隐私功能、注销泡泡计划账号或进行投诉举报。
      </div>
      <div class="userAgreement-bole">一、我们如何收集和使用您的个人信息</div>
      <div class="userAgreement-text">处理您的个人信息:</div>
      <div class="userAgreement-text">取得您的同意;</div>
      <div class="userAgreement-text">根据您的要求订立和履行合同所必需的;</div>
      <div class="userAgreement-text">
        履行法定义务所必需的，如反洗钱、反恐怖融资、反电信网络诈骗、实名管理等相关法律法规要求的必要信息;与国家安全、国防安全直接相关的;为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命
      </div>
      <div class="userAgreement-text">
        健康和财产安全所必雪;与犯罪侦查、起诉、审判和判决执行等直接相关的;所收集的个人信息是您自行公开的;从合法公开披露的信息中收集的个人信息，例如:合法的新闻报道、政府信息公开等渠道;用于维护所提供的服务的安全稳定运行所必需的，处置产品或服务的故障;
        法律法规规定的其他情形。需要注意的是，当您的个人信息处理属于前述第(2)至第(10)项情形时，我们可能会依据所适用法律法规的要求而无需再征得您的同意。
      </div>
      <div class="userAgreement-text">
        1.2帮助您成为我们的用户并提供账号服务
      </div>
      <div class="userAgreement-text">
        1.2.1账号的注册、登录为遵守法律法规的要求，您在注册并登录泡泡计划账号后才能使用评论、分享及其他用户服务，在未注册或登录账号的情况下您可以进行浏览、搜索当您注册泡泡计划账号时，您需要提供手机号码以创建账号。手机号码是履行国家法律法规关于网络实名制要求的必要信息，如您未提供手机号码用于注册、登录，我们可能无法为您提供评论、分享等功能。同时为完善您的账号信息，您可以提供非注册必须信息(如:头像、账号名、昵称、简介)来完善您的络身份标识信息。当您使用编辑头像或上传图片功能时，我们在获得您的授权同意后将访问您的相册(存储)权限。
      </div>
      <div class="userAgreement-text">
        1.2.2身份验证在您使用实名认证的功能或购买数字文化艺术品等相关服务根据相关法律法规，您需要向我们和提供认证服务的第三根据您的要求订立和履行合同所必需的;履行法定义务所必需的，如反洗钱、反恐怖融资、反电信网络诈骗、实名管理等相关法律法规要求的必要信息;与国家安全、国防安全直接相关的;为应对突发公共卫生事件，或者紧急情况下为保护自然人的生命健康和财产安全所必需;与犯罪侦查、起诉、审判和判决执行等直接相关的;所收集的个人信息是您自行公开的;从合法公开披露的信息中收集的个人信息，例如:合法的新闻报道、政府信息公开等渠道;用于维护所提供的服务的安全稳定运行所必需的，例如:发现处置产品或服务的故障;法律法规规定的其他情形。
      </div>
      <div class="userAgreement-text">
        1.3帮助您购买、转卖或转赠数字文化艺术品
      </div>
      <div class="userAgreement-text">
        1.3.1在您购买、转卖和转赠数字文化艺术品时，根据区块链信息服务以及反洗钱相关要求，您需要完成账号实名认证。如您的账号未完成实名认证，您可能无法使用购买、转卖和转赠数字文化艺术品的功能，但不影响您继续使用我们的其他服务。
      </div>
      <div class="userAgreement-text">
        1.3.2在您购买、转卖或转赠数字文化艺术品时，我们会通过系统为您生成您的购买、转卖和转赠记录并上链存储。基于区块链技术的特性，数字文化艺术品具有加密和不可篡改的特点您购买、转卖或转赠相关数字文化艺术品后，您的购买、转卖或转赠信息(交易时间、交易订单号)将进行加密处理并记录在区块链中，无法进行篡改或删除。您可以通过「我的-订单查看和管理您的交易信息。
      </div>
      <div class="userAgreement-text">1.4信息发布</div>
      <div class="userAgreement-text">
        1.4.1当您使用发布动态、评论、点赞等功能时，我们将收集您发布的信息并公开展示您的昵称、头像、发布内容，您可自行删除您发布的信息。当您使用上传图片功能时，我们在获得您的授权同意后将访问您的相册(存储)权限。如您未开启相册权限，将无法使用上述功能，但您仍可正常使用我们的其他功能。
      </div>
      <div class="userAgreement-text">
        1.4.2请您注意，您发布的内容中可能会包含您或他人的个人信息，请您在上传发布前，已确认获得合法授权或可按照法律法规所允许使用。同时基于相关法律法规的要求，为维护网络传播秩序，进一步打击仿冒搬运、造谣传谣等行为。
      </div>
      <div class="userAgreement-text">1.5互动交流</div>
      <div class="userAgreement-text">
        1.5.1当您关注您感兴趣的账号、藏品、话题，或您在进行浏览、评论、点赞等内容时，我们会收集您的浏览记录(您的关注、浏览)和点击操作记录(点击、搜索、点赞、关注、分享、评论、发布及有关行为)，并向您展示您关注的相关内容。
      </div>
      <div class="userAgreement-text">
        1.5.2在您分享或接收被分享的信息、参加活动时(如炫耀或转赠数字文化艺术品)，我们需要访问您的剪贴板、相册，以便读取其中包含的口令、分享码、二维码、链接、图片并实现跳转、分享等功能或服务。我们仅会对剪贴板、相册中的信息在您的设备本地端进行处理，不会将其中的信息回传至服务器。如您未开启剪贴板和相册权限，将无法使用上述功能，但您仍可正常使用我们的其他功能。
      </div>
      <div class="userAgreement-text">
        1.5.3请您注意，当您分享您的账号区块链地址、藏品哈希值、流转哈希值时，第三方可通过我们提供的区块链信息查询功能查看相应信息(如账号区块链地址持有的数字文化艺术品信息，具体以页面展示为准);如您不希望他人知悉或查询您的信息，请勿公开发布或对外共享。
      </div>
      <div class="userAgreement-text">1.6消息通知</div>
      <div class="userAgreement-text">
        为向您提供服务，我们可能会使用您在使用产品及/或服务的过程中提供的您的联系方式(例如:联系电话)，以便向您发送消息告知、身份验证、安全验证、用户使用体验调研、争议处理等各类通知;此外，我们也可能会通过向前述过程中收集的联系方式以短信、电话的方式，向您发送与我们服务、功能或活动相关的商业性信息;如您不愿接受这些信息，您可以通过手机短信中提供的退订方式进行退订，也可以直接与我们联系进行退订。
      </div>
      <div class="userAgreement-text">
        1.7用户服务及服务质量的改进为了提升您的服务体验并改进我们的服务质量，或者为您推荐更优质或更适合的服务，我们会收集您使用我们服务的操作记录、您与客户服务团队联系时提供的信息及您参与问卷调查时向我们发送的信息。如您不提供前述信息，不影响您使用我们提供的其他服务。为了分析服务的新增、激活、留存、性能等统计性指标，我们可能会调用剪贴板对经匿名化、去标识化处理的信息进行归因分析。
      </div>
      <div class="userAgreement-text">1.8个性化推荐</div>
      <div class="userAgreement-text">
        1.8.1为了提升您的产品体验，向您推荐更为优质或适合的服务，我们可能会收集您在使用我们的产品/服务时的可变更的唯一设备识别码、浏览使用信息(点击、浏览、关注、分享、评论)、数字文化艺术品购买记录，提取您的偏好特征，我们将根据用户信息形成群体特征标签，用于向您展示您可能感兴趣的数字文化艺术品信息、账号、用户发布信息(动态、评论)、营销活动通知、商业性电子信息。
      </div>
      <div class="userAgreement-text">1.9安全管理</div>
      <div class="userAgreement-text">
        为了提高您使用我们产品/服务过程中的安全性，防止您的账号、资金、个人信息被不法分子获取，预防病毒、木马程序或其他恶意程序、网站，我们需要记录您使用的服务类别、方式及相关操作信息，例如:可变更的唯一设备识别码、不可变更的唯一设备识别码、基本设备信息、网络信息、设备软件安装列表、所在地区、网络使用习惯以及其他与服务相关的日志信息。上述信息是我们用于维护产品/服务安全稳定运行的必要信息，如您不能提供前述信息，可能无法完成您所使用产品/服务的风控验证。
      </div>
      <div class="userAgreement-text">
        1.10基于系统权限为您提供的其他附加服务
      </div>
      <div class="userAgreement-text">
        1.10.1为向您提供更便捷、更优质、个性化的产品/服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会通过开启系统权限的方式以收集和使用您的个人信息。如果您不后意开启相关权限，不会影响您使用我们提供的其他服务，但您可能无法获得这些附加服务给您带来的用户体验。您可在您的设备“设置”或我们客户端“我的-设置-通用设置-系统权限管理中逐项查看上述权限的状态，并可自行决定这些权限的开启或关闭。
      </div>

      <div class="userAgreement-text">
        1.10.1为向您提供更便捷、更优质、个性化的产品/服务，努力提升您的体验，我们在向您提供的以下附加服务中可能会通过开启系统权限的方式以收集和使用您的个人信息。如果您不后意开启相关权限，不会影响您使用我们提供的其他服务，但您可能无法获得这些附加服务给您带来的用户体验。您可在您的设备“设置”或我们客户端“我的-设置-通用设置-系统权限管理中逐项查看上述权限的状态，并可自行决定这些权限的开启或关闭。
      </div>

      <div class="userAgreement-bole">二、我们如何使用Cookie等技术</div>
      <div class="userAgreement-text">
        2.1为使您获得更轻松的访问体验，您访问泡泡计划平台或使用泡泡计划提供的产品/服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账号安全状态。这些数据文件可能是Cookie，FlashCookie，您的浏览器或关联应用程序提供的其他本地存储(以请您理解，我们的某些服务只能通过使用下简称“Cookie”)。Cookie才可得到实现。如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝泡泡计划的Cookie。多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新Cookie时通知您或者怎样彻底关闭Cookie。此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的FlashCookie及类似数据。但这一举动在某些情况下可能会影响您安全访问泡泡计划平台和使用泡泡计划提供的服务。
      </div>
      <div class="userAgreement-bole">三、我们如何存储您的个人信息。</div>
      <div class="userAgreement-text">
        3.1我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。目前，我们不会将上述信息传输至境外，如果我们向境外传输，会严格遵照法律法规和相关监管部门的规定执行，向您说明个人信息出境的目的以及涉及的个人信息类型，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。
      </div>
      <div class="userAgreement-text">
        3.2我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息，例如《中华人民共和国电子商务法》要求商品和服务信息、交易信息保存时间自交易完成之日起不少于三年。如您注销账号或超出必要的保存期限后，我们会根据适用法律法规的要求对所持有的您的个人信息进行删除或匿名化处理。
      </div>
      <div class="userAgreement-text">
        3.3如果我们发生停止产品/服务等情形，我们将会至少提前十日向您发出通知，并在停止产品/服务后根据适用法律法规的要求对所持有的您的个人信息进行删除或匿名化处理。
      </div>
      <div class="userAgreement-bole">四、我们如何保护您的个人信息</div>
      <div class="userAgreement-text">
        4.1为了保障您的信息安全，我们在收集您的信息后，将采取种合理必要的措施保护您的信息。例如，在技术开发环境当中，我们仅使用经过去标识化处理的信息进行统计分析，对外提供研究报告时，我们将对报告中所包含的信息进行去标识化处理。我们会将去标识化后的信息与可用于恢复识别个人的信息分开存储，确保在针对去标识化信息的后续处理中不重新识别个人。
      </div>
      <div class="userAgreement-text">
        4.2我们承诺我们将使信息安全保护达到业界领先的安全水平为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如:通过网络安全层软件(SSL)进行加密传输、信息加密存储、严格限制数据中心的访问。传输和存储敏感个人信息(含个人生物识别信息)时我们将采用加密、权限控制、去标识化/匿名化等安全措施。我们会将去标识化后的信息与可用于恢复识别个人的信息分开存储，确保在针对去标识化信息的后续处理中不重新识别个人。我们会采用物理或逻辑隔离方式分别存储人脸识别数据和个人身份信息，并采用加密存储等安全措施存储人脸识别数据。
      </div>
      <div class="userAgreement-text">
        4.3我们设立了个人信息保护责任部门，针对个人信息处理开展个人信息安全影响评估。同时，我们建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权的原则;对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并每年组织全体工作人员参加安全考试。另外，我们的相应网络系统通过了国家网络安全等级保护的测评。我们每年还会聘请外部独立第三方对我们的信息安全管理体系进行评估。
      </div>
      <div class="userAgreement-text">
        4.4我们已制定个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知:安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以平台推送通知、发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担应的法律责任。
      </div>
      <div class="userAgreement-text">
        4.5请您务必妥善保管好您的泡泡计划登录名及其他身份要素。您在使用我们提供的产品/服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能对您产生不利。如您发现您的登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。
      </div>
      <div class="userAgreement-text">
        4.6在您终止使用某项服务后，我们会停止对您个人信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对我们所持有的您的个人信息进行删除或匿名化处理。
      </div>
      <div class="userAgreement-bole">五、我们如何对外提供个人信息</div>
      <div class="userAgreement-text">
        5.1委托处理为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的关联公司或其他第三方代表我们来处理您的信息。我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务并采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，受托方不再保存个人信息。我们承诺对此负责。
      </div>
      <div class="userAgreement-text">5.2共享</div>
      <div class="userAgreement-text">
        5.2.1业务共享我们承诺对您的信息进行保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括关联公司、合作机构以及其他合作伙伴。在将信息提供给第三方前，我们将尽商业上合理的努力评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规，尽力要求第三方对您的信息采取保护措施。
      </div>
      <div class="userAgreement-text">
        (1)向您提供服务所必需的共享。某些产品或服务可能由第三方提供或由相关服务提供方与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或服务，例如:区块链技术服务的提供方、数字文化艺术品的发行方、实物商品定制。
      </div>
      <div class="userAgreement-text">
        (2)如您选择参与我们和第三方联合开展的运营活动(如抽奖、竞赛或类似推广活动)，我们可能与其共享活动过程中产生的、为完成活动所必要的信息(包括但不限于您的专属邀请码、您的数字文化艺术品的持有数量和转赠数量等)，以便第三方能及时向您发放奖品或为您提供服务，相关服务提供方会依据法律法规或国家标准的要求，在活动规则页面或通过其他途径向您明确告知需要向第三方提供何种个人信息。
      </div>
      <div class="userAgreement-text">
        (3)事先获得您明确同意的情况下，我们会在法律法规允许目不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。
      </div>
      <div class="userAgreement-text">5.2.2其他用户查询</div>
      <div class="userAgreement-text">
        为了确保数字文化艺术品转赠对象的准确性，避免因输错账号导致的损失，他人可通过搜索您的手机号搜索到您，并看到您账号对应的昵称、头像，以便向您转赠数字文化艺术品。
      </div>
      <div class="userAgreement-text">
        5.2.3投诉处理当您投诉他人或被他人投诉时，为了保护您及他人的合法权益，我们可能会将您的姓名、有效证件号码、联系方式、投诉相关内容等提供给消费者权益保护部门及监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外，
      </div>
      <div class="userAgreement-text">5.3转让</div>
      <div class="userAgreement-text">
        我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外:
      </div>
      <div class="userAgreement-text">(1)事先获得您的明确同意;</div>
      <div class="userAgreement-text">
        (2)根据法律法规或强制性的行政或司法要求;
      </div>
      <div class="userAgreement-text">
        (3)在涉及资产转让、收购、兼并、重组或破产清算时，如及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的、处理方式时，我们将要求该公司、组织重新取得您的明确同意。
      </div>
      <div class="userAgreement-text">5.4公开披露</div>
      <div class="userAgreement-text">
        5.4.1除下述情况外，原则上我们不会将您的信息进行公开披露。如确需公开披露的，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明确同意。(1)您的关注列表、关注数、粉丝列表、粉丝数将在您的个人主页中展示;同时您发布的评论内容也会和您的账号昵称、头像一并在您发布的页面中进行展示;
      </div>
      <div class="userAgreement-text">
        (2)在公布中奖活动名单时会根据具体活动参与规则脱敏展示中奖者手机号或账号登录名;
      </div>
      <div class="userAgreement-text">
        (3)对违规账号、欺诈行为进行处罚公告时，我们会公开相关账号信息;
      </div>
      <div class="userAgreement-text">(4)法律、行政法规规定的其他情形。</div>
      <div class="userAgreement-text">
        5.4.2特别提示您注意，您在使用我们产品/服务时自愿发布或公开分享的信息(如分享您拥有的数字文化艺术品)，可能会涉及您或他人的个人信息甚至敏感个人信息(如您的账号昵称、头像、交易信息)。请您在使用我们的服务时谨慎地考虑，是否要发布甚至公开分享相关信息。
      </div>
      <div class="userAgreement-bole">六、您如何实现您的个人信息权利</div>
      <div class="userAgreement-text">
        在您使用我们的产品/服务期间，为了您可以更加便捷地访问和管理您的信息，同时保障您的个人信息权利，我们在客户中为您提供了相应的操作设置，您可以参考下面的指引进行作。6.1查阅、更正和补充您有权访问、更正、补充您的个人信息，法律法规规定的例外情况除外。您在泡泡计划平台中登录账号后，可以通过以下方式管理您的信息:
      </div>
      <div class="userAgreement-text">
        (1)账号信息:通过访问“我的--个人信息/实名认证”访问、更正、补充您账号中的个人资料及账号信息、账号绑定信息及实名认证状态。
      </div>
      <div class="userAgreement-text">
        (2)订单信息:通过访问“我的-我的订单”查询您账号中购买的数字文化艺术品订单信息。
      </div>
      <div class="userAgreement-text">
        (3)转卖/转赠/接收记录:通过访问:“我的-寄售管理”查询您订单中的数字文化艺术品转卖、转赠、接收记录。如您发现相应信息有误的，您可以根据页面提示自助修改您的信息，您也可以通过访问:“首页-联系我们”联系泡泡计划官方要求更正。出于安全性和身份识别的考虑或根据法律法规及监管规定，您需通过验证方可修改您已认证的姓名或已绑定的手机号码。
      </div>
      <div class="userAgreement-text">6.2删除</div>
      <div class="userAgreement-text">
        您可以通过6.1中列明的路径删除您的部分信息，或申请注销账号删除您的全部信息。如您发现我们收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可以通过与客服联系向我们提出删除个人信息的请求。但如有相关个人信息按照法律法规规定的保存期限未届满或者其他依法不适宜删除的情形，我们将停止除存储和采取必要的安全保护措施之外的处理。
      </div>
      <div class="userAgreement-text">
        6.3管理您的授权范围您可以通过手机设备权限设置来管理泡泡计划平台使用的系统权授权。对于您无法直接通过上述方式设置的授权，您可以通过“首页-联系我们”联系泡泡计划官方进行修改。但请注意，对于部分类型的个人信息，例如实现泡泡计划平台基本功能所必须的信息或者我们履行法律法规规定的义务所必需的信息，我们可能无法响应您改变授权范围的请求。当您撤回授权后，我们将不再处理相应的个人信息。但您撤回授权的决定，不会影响我们此前基于您的授权而开展的个人信息处理。
      </div>
      <div class="userAgreement-text">
        6.4注销账号您可通过如下路径申请注销账号:在泡泡计划平台中登录账号，通过访问“我的-设置-注销账号”即可进入账号注销申请流程。当您符合约定的账号注销条件后，我们将按照法律要求在十五个工作日内完成账号注销事宜。
      </div>
      <div class="userAgreement-text" style="text-decoration-line: underline">
        6.5尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求:
      </div>
      <div class="userAgreement-text" style="text-decoration-line: underline">
        (1)与国家安全、国防安全直接相关的;
      </div>
      <div class="userAgreement-text" style="text-decoration-line: underline">
        (2)与公共安全、公共卫生、重大公共利益直接相关的;
      </div>
      <div class="userAgreement-text" style="text-decoration-line: underline">
        (3)与犯罪侦查、起诉、审判和执行判决等直接相关的;
      </div>
      <div class="userAgreement-text" style="text-decoration-line: underline">
        (4)有充分证据表明您存在主观恶意或滥用权利的;
      </div>
      <div class="userAgreement-text" style="text-decoration-line: underline">
        (5)响应您的请求将导致其他个人、组织的合法权益受到严重损害的。
      </div>
      <div class="userAgreement-text">
        6.6当我们与第三方合作向您提供服务时，第三方将按照其发布的隐私政策或与您签署的其他条款(如有)告知您个人信息处理者的相关信息，并按照适用的要求严格保护您的信息、告知您行使个人信息权利的方式。请您仔细阅读相关政策、条款;如有疑问，可以根据您所使用的服务咨询该第三方机构。
      </div>
      <div class="userAgreement-text">
        6.7如您对本政策存在任何疑问，或对于您的个人信息处理存在任何投诉、意见，您可以通过以下渠道联系我们电子邮箱:loki@bubbleplan.cn泡泡计划隐私保护责任部门将会及时答复您。为保障您的信息安全，我们需要先验证您的身份和凭证资料。我们已经建立客户投诉管理机制，包括跟踪流程。我们将在不超过十五个工作内作出答复。
      </div>
      <div class="userAgreement-bole">七、对第三方责任的声明</div>
      <div class="userAgreement-text" style="text-decoration-line: underline">
        7.1请您注意，您的交易相对方、您访问的第三方网站经营者通过我们接入的第三方服务(例如:数字文化艺术品的发行方)和通过我们接收您的个人信息的第三方可能有自己的隐私保护政策;当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关作以保护您的合法权益。
      </div>
      <div class="userAgreement-text">
        <span style="text-decoration-line: underline">
          7.2我们所提供的部分服务需要由合作伙伴共同为您提供(例如:平台的通知栏消息提醒或支付服务)，为此，我们会在泡泡计划平台内会嵌入合作伙伴的软件工具开发包(以下简称“SDK”)或其他类似的应用程序。</span
        >
        例如:当您使用不同手机设备时，手机厂商提供的PushSDK(消息提醒服务)需要读取您的设备识别号、联网相关信息，用于推送/下发通知栏消息。我们将会对SDK或其他类似的应用程序进行严格的安全检测:并要求合作伙伴采取严格的数据保护措施，切实保障您的合法权益。相应的措施。
      </div>
      <div class="userAgreement-bole">八、我们如何保护未成年人的信息</div>
      <div class="userAgreement-text">
        8.1我们非常重视对未成年人个人信息的保护。我们期望父母或监护人指导未成年人使用我们的服务。我们将根据国家相关法律法规的规定保护未成年人信息的保密性及安全性。
      </div>
      <div class="userAgreement-text">
        <span style="text-decoration-line: underline">
          8.2如您为未成年人，建议请您的父母或监护人阅读本政策，并在征得您父母或监护人同意的前提下使用我们的服务或向我们提供您的信息。</span
        >
        对于经父母或监护人同意而收集您的信息的情况，我们只会在受到法律允许、父母或监护人明确同意或者保护您的权益所必要的情况下使用或公开披露此信息。如您的监护人不同意您按照本政策使用我们的服务或向我们提供信息，请您立即终止使用我们的服务并及时通知我们，以便我们采取相应的措施。
      </div>
      <div class="userAgreement-text">
        8.3如您为未成年人的父母或监护人，当您对您所监护的未成年人的信息处理存在疑问时，请通过上文中的联系方式联系我们。
      </div>
      <div class="userAgreement-bole">九、本政策的适用和更新</div>
      <div class="userAgreement-text">
        9.1为给您提供更好的服务，我们提供的产品/服务将不时变化，本政策也将随之更新，我们也会根据国家法律法规的变化要求，更新本政策的相关条款。更新的政策将于更新公告公布的生效之日起生效。
      </div>
      <div class="userAgreement-text">
        9.2发生下列重大变化情形时，我们会适时对本政策进行更新:
      </div>
      <div class="userAgreement-text">
        (1)我们的基本情况发生变化，例如:兼并、收购、重组引起的所有者变更;
      </div>
      <div class="userAgreement-text">
        (2)收集、存储、使用个人信息的范围、目的、规则发生变化;
      </div>
      <div class="userAgreement-text">
        (3)对外提供个人信息的对象、范围、目的发生变化;
      </div>
      <div class="userAgreement-text">
        (4)您访问和管理个人信息的方式发生变化;
      </div>
      <div class="userAgreement-text">
        (5)数据安全能力、信息安全风险发生变化;
      </div>
      <div class="userAgreement-text">
        (6)用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化;
      </div>
      <div class="userAgreement-text">
        (7)其他可能对您的个人信息权益产生重大影响的变化。
      </div>
      <div class="userAgreement-text">
        9.3如本政策发生更新，我们将以平台推送通知、弹窗提示、发送邮件/短消息或者在官方平台发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用我们提供的产品/服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。
      </div>
      <div class="userAgreement-bole">十、本政策中关键词说明</div>
      <div class="userAgreement-text">
        版本个人信息:是以电子或者其他方式记录的与已识别或者可识别的自然人有关的各种信息，不包括匿名化处理后的信息。敏感个人信息:是一旦泄露或者非法使用，容易导致自然人的人格尊严受到侵害或者人身、财产安全受到危害的个人信息包括生物识别、宗教信仰、特定身份、医疗健康、金融账户行踪轨迹等信息，以及不满十八周岁未成年人的个人信息。个人信息的处理:包括个人信息的收集、存储、使用、加工传输、提供、公开、删除等。账号信息:指用于识别您身份的泡泡计划账号相关信息要素，包括您的泡泡计划ID、手机号码、密码、短信校验码、头像、昵称、简介、证件号码及生物识别信息。数字文化艺术品:是使用区块链技术进行唯一标识的经数字化的特定作品、艺术品和商品，包括但不限于数字图片、音乐、视频、3D模型等各种形式的数字文化艺术品。数字文化艺术品的具体发行方和权益规则等信息以相关页面的具体展示为准。可变更的唯一设备识别码:是指用户可重置、变更或关闭追踪的唯一设备识别码，如Android
        ID、OAID、SSID、BSSID不可变更的唯一设备识别码:是指不因设备恢复出厂设置、应用安全卸载或用户操作而改变的硬件标识符，如IMEI、IMSIMAC地址、设备序列号、硬件序列号(如传感器硬件序列号等)、SIM卡序列号、ICCID
        基本设备信息:设备机型相关信息，通常用于功能适配、稳定性等功能，如系统设置、系统属性、设备型号、设备品牌、操作系统。
      </div>
      <div class="userAgreement-text">
        网络信息:如IP地址、网络类型、运营商信息、Wi-Fi状态Wi-Fi参数、Wi-Fi列表。
      </div>
    </div>
  </div>
</template>
  <script>
export default {
  data() {
    return {};
  },
  methods: {
    back() {
      history.back();
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.pagess {
  width: 100%;
  background: #9a13a5;
  //   padding-top: 80px;
  position: relative;

  &-bach {
    position: absolute;
    width: 50px;
    height: 50px;
    top: 76px;
    left: 90px;
    z-index: 9;
  }
  &-bach:hover {
    cursor: pointer;
  }
}
.userAgreement::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.userAgreement {
  width: 70%;
  margin: auto;
  padding-top: 80px;
  line-height: 40px;
  color: #fff;
  height: calc(100vh - 94px);
  overflow: auto;
  font-size: 20px;
  &-title {
    font-weight: 700;
  }
  &-text {
  }
  &-bole {
    font-weight: 900;
    margin: 10px 0;
  }
}
</style>